import React, { FC, useMemo, useState, useEffect } from 'react';
import { noop } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Lottie from 'react-lottie';
import { useMedia } from 'react-use';
import yellowStar from 'static/animations/yellowStar.json';
import { FilterIcn, NoResponse } from 'static';
import DatePickerControlled from 'components/Inputs/DatePickers/DatePickerControlled';
import SelectInputControlled from 'components/Inputs/SelectInputControlled/SelectInputControlledV2';
import FilterTooltip from 'components/FilterTooltip';

import {
  StyledFilterBox,
  StyledIconsBlock,
  StyledIcon,
  FilterContainer,
  StyledMiddleIcon,
  StyledFilterText,
} from './styled';
import { SchedulerFilter } from 'types';
import { MyEventsFilterValues } from 'pages/UserProfileEvents';
import { getEventTypes } from 'store/selectors/eventTypes';
import { FilterBlock } from 'pages/UserProfileEvents/styled';
import { StyledResetButtonBlock } from 'pages/CommunityOverallScheduler/Schedule/styled';
import ResetButton from 'components/Buttons/ResetButton';
import Button from 'components/Buttons/Button';
import { UnrespondedEventsCircle } from 'components/ProfileMenu/styled';
import {
  getFilteredUnrespondedEventsCount,
  getUnrespondedEventsCount,
} from 'store/selectors/events';

import { breakpoints } from 'theme';
import { Tooltip } from 'components';
interface MyAppointmentFiltersProps {
  width?: string;
  filters?: MyEventsFilterValues;
  noEndTime?: boolean;
  noApply?: boolean;
  fillWidth?: boolean;
  eventTypesOptions?: { [p: string]: string }[];
  setFilters?: (value: SchedulerFilter | MyEventsFilterValues) => void;
  applyFilters?: (value: SchedulerFilter | MyEventsFilterValues) => void;
  resetFilters?: () => void;
  shouldResetLocalFilters?: boolean;
}

const MyAppointmentFilters: FC<MyAppointmentFiltersProps> = ({
  width,
  filters,
  noEndTime,
  noApply,
  fillWidth,
  eventTypesOptions: customEventTypes,
  setFilters = noop,
  applyFilters = noop,
  resetFilters: onReset = noop,
  shouldResetLocalFilters,
}) => {
  const { t } = useTranslation();
  const eventTypes = useSelector(getEventTypes) || [];
  const [filtersVisible, setFiltersVisible] = useState<boolean>(false);
  const filteredUnrespondedEventsCount = useSelector(
    getFilteredUnrespondedEventsCount,
  );
  const isDownSmPlus = useMedia(breakpoints.downSmPlus);

  const [localFilters, setLocalFilters] =
    useState<MyEventsFilterValues>(filters);
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);

  const eventTypesOptions = useMemo(() => {
    if (customEventTypes) {
      return customEventTypes;
    }

    return (
      eventTypes && [
        ...eventTypes.map((type) => ({ label: type.title, value: type.id })),
        { label: t('common.all'), value: 'all' },
      ]
    );
  }, [customEventTypes, eventTypes]);

  const setNominationOrAwaiting = (filterBy) => {
    setFilters((prevFilterState) => ({
      ...prevFilterState,
      awaiting: filterBy === 'awaiting' ? !prevFilterState.awaiting : false,
      nomination:
        filterBy === 'nomination' ? !prevFilterState.nomination : false,
    }));
  };

  const onFilterSubmit = () => {
    if (!Object.is(filters, localFilters)) {
      applyFilters((filterValues) => ({
        ...filterValues,
        from: localFilters?.from,
        eventType: localFilters?.eventType,
      }));
      setIsFilterApplied(true);
    }
    setFiltersVisible(false);
  };

  const resetLocalFilters = () => {
    setLocalFilters((prevFilter) => ({
      ...prevFilter,
      from: moment().valueOf(),
      eventType: { label: 'All', value: 'all' },
    }));
    setIsFilterApplied(false);
  };

  useEffect(() => {
    setLocalFilters((prevFilter) => ({
      ...prevFilter,
      from: filters.from,
      awaiting: false,
      nomination: false,
    }));
  }, [shouldResetLocalFilters]);

  return (
    <StyledFilterBox
      fillWidth
      widthByLetterSize={t('filters.filterBy')?.length ?? 0}
    >
      <FilterContainer>
        <StyledFilterText>{t('filters.filterBy')}:</StyledFilterText>
        <StyledIconsBlock>
          <StyledIcon
            paddingRight={'2px'}
            selected={filters?.awaiting}
            onClick={() => {
              setNominationOrAwaiting('awaiting');
            }}
          >
            <NoResponse />
            {filteredUnrespondedEventsCount ? (
              <UnrespondedEventsCircle
                positionLeft={'12px'}
                // positionTop={'-1px'}
              >
                <span>
                  {filteredUnrespondedEventsCount > 99
                    ? '99+'
                    : filteredUnrespondedEventsCount}
                </span>
              </UnrespondedEventsCircle>
            ) : null}
          </StyledIcon>
          <StyledMiddleIcon>
            <StyledIcon
              selected={filters?.nomination}
              onClick={() => {
                setNominationOrAwaiting('nomination');
              }}
              star
            >
              {/* <AttendedStar /> */}
              <Lottie
                options={{
                  animationData: yellowStar,
                  autoplay: true,
                  loop: true,
                }}
                width={48}
                height={48}
                style={{ position: 'absolute' }}
              />
            </StyledIcon>
          </StyledMiddleIcon>

          <FilterTooltip
            InnerComponent={
              <FilterBlock>
                <div>
                  <DatePickerControlled
                    label={t('common.from')}
                    date={localFilters?.from}
                    popperModifiers={{
                      flip: { behavior: ['bottom'] },
                      preventOverflow: { enabled: false },
                      hide: { enabled: false },
                    }}
                    handleChange={(date) =>
                      setLocalFilters((prevFilter) => ({
                        ...prevFilter,
                        from: new Date(date),
                      }))
                    }
                  />
                </div>
                {!noEndTime && (
                  <div>
                    <DatePickerControlled
                      label={t('common.to')}
                      date={filters['to']}
                      popperModifiers={{
                        flip: { behavior: ['bottom'] },
                        preventOverflow: { enabled: false },
                        hide: { enabled: false },
                      }}
                      handleChange={(date) =>
                        setFilters({
                          ...filters,
                          to: new Date(date),
                        })
                      }
                    />
                  </div>
                )}
                <div>
                  <SelectInputControlled
                    options={eventTypesOptions}
                    width="100%"
                    label={t('event.eventType')}
                    value={localFilters?.eventType}
                    maxMenuHeight={200}
                    onChange={(v) =>
                      setLocalFilters((prevFilter) => ({
                        ...prevFilter,
                        eventType: v,
                      }))
                    }
                  />
                </div>
                <StyledResetButtonBlock>
                  <ResetButton
                    onClick={() => {
                      resetLocalFilters();
                      onReset();
                    }}
                  />
                </StyledResetButtonBlock>
                {!noApply && (
                  <Button width="100%" onClick={onFilterSubmit}>
                    {t('common.apply')}
                  </Button>
                )}
              </FilterBlock>
            }
            isVisible={filtersVisible}
            position="bottom-start"
            tooltipStyle={{}}
            closeTooltip={() => {
              setFiltersVisible((prevBoolValue) => !prevBoolValue);
            }}
            arrowOffset={isDownSmPlus ? 48 : undefined}
          >
            <Tooltip
              isVisible
              text={t('filters.clickToSelect')}
              opacity="1"
              offset={9}
              tooltipStyle={{
                whiteSpace: 'pre-wrap',
                maxWidth: '200px',
                height: 'auto',
              }}
            >
              <StyledIcon
                id="toggle-filters-icon"
                selected={filtersVisible || isFilterApplied}
                onClick={() =>
                  setFiltersVisible((prevBoolValue) => !prevBoolValue)
                }
              >
                <FilterIcn />
              </StyledIcon>
            </Tooltip>
          </FilterTooltip>
        </StyledIconsBlock>
      </FilterContainer>
    </StyledFilterBox>
  );
};

export default MyAppointmentFilters;
