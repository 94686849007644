import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { Button, Divider, UserAvatar } from 'components';
import { useDateDetails } from 'hooks';
import { getDateString, getProfileName } from 'utils';
import { getEventTypes } from 'store/selectors/eventTypes';
import { getSettings } from 'store/selectors/auth';
import { getCurrentCommunity } from 'store/selectors/currentCommunity';
import StatisticsBlock from './StatisticsBlock';
import { CommunityRoles, CommunityUser, Event } from 'types';
import { getTextColor } from 'utils';

import Paragraph from 'components/Paragraph';

import {
  StyledContainer,
  StyledTitle,
  StyledIconTextBlock,
  StyledText,
  StyledSmallText,
  StyledTextBlock,
  StyledOrganizerBlock,
  BlackDot,
  StyledOptionalMessage,
  DateTag,
  OptionalMessageList,
  OptionalMessageListItem,
  OptionalMessageAll,
} from './styled';
import ChangeSidebarBox from 'sidebars/common/ChangeSidebarBox';
import TextWithMore from 'components/TextWithMore';
import { BackIconComponent } from 'static';
import { getTime } from 'utils/dates';

interface EventDetailsEventProps {
  event: Event;
  currentUser: CommunityUser;
  date: Date;
  width: number;
  eventResponseId: string;
  openTotal: boolean;
  onViewReplyClick: () => void;
}

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
    },
  },
};

const EventDetailsEvent: FC<EventDetailsEventProps> = ({
  event,
  currentUser,
  date,
  width,
  eventResponseId,
  openTotal,
  onViewReplyClick,
}) => {
  const { t } = useTranslation();
  const eventTypes = useSelector(getEventTypes);
  const settings = useSelector(getSettings);
  const community = useSelector(getCurrentCommunity);
  const organizer = community.users?.find((u) => u.id === event.userId);
  const [showOptionalGroup, setShowOptionalGroup] = useState<boolean>(false);
  const [isCollapsed] = useState<boolean>(true);
  const userRole = currentUser?.communityPermission.role;

  const { repeatString, timeInterval, dateStringWithDay } = useDateDetails(
    event,
    date,
    settings.timeFormat,
  );

  const getColor = useCallback(getTextColor, [event]);

  const optionMessageCardsList = [...event.optionalMessages].slice(0, 3);

  const reversedOptionalMessageList = useMemo(() => {
    if (event?.optionalMessages?.length > 0) {
      return [...event.optionalMessages].reverse();
    }
  }, [event]);

  const eventType = useMemo(() => {
    return eventTypes.filter((type) => type.id === event.eventTypeId)[0]?.title;
  }, [event, eventTypes?.length]);

  const eventTypeColor = useMemo(() => {
    return eventTypes.filter((type) => type.id === event.eventTypeId)[0]?.color;
  }, [event, eventTypes?.length]);

  const handleOpenSideBar = (data?: string) => {
    if (data === 'myResponse') {
      onViewReplyClick();
    }
  };

  return (
    <StyledContainer>
      {showOptionalGroup ? (
        <OptionalMessageAll>
          <Button
            IconComponent={BackIconComponent}
            variant="secondary"
            width="max-content"
            style={{ alignSelf: 'baseline' }}
            onClick={() => {
              setShowOptionalGroup(false);
            }}
          >
            {t('common.back')}
          </Button>
          <Divider customMargin="0 0 0 0" />
          <OptionalMessageList
            variants={container}
            initial="hidden"
            animate="visible"
          >
            {reversedOptionalMessageList?.map((eachData, idx) => (
              <OptionalMessageListItem
                key={idx}
                variants={item}
                backgroundColor={eventTypeColor}
                textColor={getColor(eventTypeColor)}
              >
                <DateTag>
                  <p style={{ display: 'inline', color: 'white' }}>
                    {`${t(
                      `dayOfWeek.day${moment(eachData.timeStamp).day()}`,
                    )}, ${getDateString(new Date(eachData.timeStamp))}`}
                    , {getTime(eachData.timeStamp)}
                  </p>
                </DateTag>
                <OptionalMessageListItem.Message>
                  {eachData.message}
                </OptionalMessageListItem.Message>
              </OptionalMessageListItem>
            ))}
          </OptionalMessageList>
        </OptionalMessageAll>
      ) : (
        <>
          {(event.recipients.includes(currentUser.id) ||
            [CommunityRoles.ADMIN, CommunityRoles.OWNER].includes(
              userRole,
            )) && (
            <ChangeSidebarBox onClick={handleOpenSideBar} primary={true} />
          )}
          <Divider customMargin="0px 0px 16px 0px" />
          <StyledTitle>{event.title}</StyledTitle>
          <div style={{ height: 'auto' }}>
            {optionMessageCardsList.map((messageData, index) => (
              <StyledOptionalMessage
                lastIndex={optionMessageCardsList.length - 1 === index}
                whileHover={{ y: isCollapsed && -10 }}
                animate={{
                  zIndex: index + 1,
                  cursor:
                    isCollapsed &&
                    index !== optionMessageCardsList.length - 1 &&
                    'pointer',
                }}
                // top={index}
                index={isCollapsed ? index : 0}
                key={index}
                backgroundColor={eventTypeColor}
                isCollapsed={isCollapsed}
                onClick={() => {
                  index !== optionMessageCardsList.length - 1 &&
                    setShowOptionalGroup(true);
                }}
              >
                <StyledOptionalMessage.MessageBlock>
                  <DateTag
                    showPlusButton={
                      index === 0 && optionMessageCardsList.length > 1
                    }
                  >
                    {index === 0 && optionMessageCardsList.length > 1 ? (
                      <p>+ {event?.optionalMessages?.length}</p>
                    ) : (
                      <p style={{ display: 'inline' }}>
                        {`${t(
                          `dayOfWeek.day${moment(messageData.timeStamp).day()}`,
                        )}, ${getDateString(new Date(messageData.timeStamp))}`}
                        , {getTime(messageData.timeStamp)}
                      </p>
                    )}
                  </DateTag>
                  <StyledOptionalMessage.Message
                    textColor={getColor(eventTypeColor)}
                  >
                    <Paragraph
                      backgroundColor={eventTypeColor}
                      textColor={getColor(eventTypeColor)}
                      text={messageData.message}
                      rows={2}
                      style={{
                        visibility:
                          index === 0 && optionMessageCardsList.length !== 1
                            ? 'hidden'
                            : 'visible',
                      }}
                    />
                  </StyledOptionalMessage.Message>
                </StyledOptionalMessage.MessageBlock>
              </StyledOptionalMessage>
            ))}
          </div>
          <StyledIconTextBlock
            marginTop={
              optionMessageCardsList?.length === 0
                ? 0
                : optionMessageCardsList.length
            }
          >
            <BlackDot />
            <StyledTextBlock addCss={dateExtraStyle}>
              <StyledText bold>{dateStringWithDay}</StyledText>
              <StyledText bold>{timeInterval}</StyledText>
              {event.isRepeated && <StyledText>{repeatString}</StyledText>}
            </StyledTextBlock>
          </StyledIconTextBlock>
          <StyledIconTextBlock>
            <BlackDot />
            <TextWithMore text={event.location} afterMore={12} />
          </StyledIconTextBlock>
          {event.description && (
            <StyledIconTextBlock>
              <BlackDot />
              <Paragraph key={988} text={event.description} rows={2} />
            </StyledIconTextBlock>
          )}
          <StyledIconTextBlock>
            <BlackDot />
            <StyledText color={eventTypeColor} bold>
              {eventType}
            </StyledText>
          </StyledIconTextBlock>
          <StyledOrganizerBlock>
            <UserAvatar
              avatar={organizer?.profile.smallLogo || organizer?.profile.logo}
              alt="profile pic"
              style={{
                width: '32px',
                height: '32px',
                minWidth: '32px',
                margin: 0,
              }}
            />
            <StyledTextBlock addCss={organizerExtraStyle}>
              <StyledText>
                {organizer ? getProfileName(organizer.profile) : 'User'}
              </StyledText>
              <StyledSmallText>{t('event.eventOrganizer')}</StyledSmallText>
            </StyledTextBlock>
          </StyledOrganizerBlock>
          <Divider customMargin="16px 0px 0px 0px" />
          <StatisticsBlock
            event={event}
            openTotal={openTotal}
            eventResponseId={eventResponseId}
            width={width}
          />
        </>
      )}
    </StyledContainer>
  );
};

export default EventDetailsEvent;

const organizerExtraStyle = {
  paddingLeft: '8px',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'row',
};

const dateExtraStyle = {
  flexDirection: 'column',
};
