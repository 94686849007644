import React from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie';

import newIconAnimation from 'static/animations/new.json';

import { CloseButton, Button } from 'components';

import { Wrapper } from '../FeaturePopup/styled';
import DefaultDe from 'static/images/default.png';
import { CalwithDate, GreenCircle, UpArrow } from 'static';
import { Image } from 'components/InfoPopup/styled';
import {
  StyledBody,
  StyledHeader,
  StyledPopup,
  StyledFooter,
  StyledAnchorTag,
} from './../importPopup/styled';
import { ROUTES } from 'configs';
import {
  getCurrentCommunity,
  getCurrentUserInCommunity,
} from 'store/selectors/currentCommunity';
import { useSelector } from 'react-redux';
import { hasCommunityOrGroupAdminPermissions } from 'utils';
import { useToasts } from 'react-toast-notifications';

type Props = {
  onClose: () => void;
  isOpen: boolean;
};

const PrefrencePopup: React.FC<Props> = ({ onClose, isOpen }) => {
  const community = useSelector(getCurrentCommunity);
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const currentUser = useSelector(getCurrentUserInCommunity);
  const { t, i18n } = useTranslation();
  const pushSupportPageUrl =
    i18n.language === 'de'
      ? 'https://gumb.app/support/ereignistypen-erstellen/'
      : 'https://gumb.app/en/support/create-event-types/';

  const imageUrl = DefaultDe;

  const handleClick = () => {
    if (!hasCommunityOrGroupAdminPermissions(currentUser.id, community)) {
      addToast(t('errors.onlyForGroupAndAdmin'), {
        appearance: 'info',
        autoDismiss: true,
      });
      onClose();
      return;
    }
    navigate(
      `${ROUTES.COMMUNITY}/${community.id}/${ROUTES.COMMUNITY_SETTINGS}/#event-types`,
    );
    onClose();
  };
  const buttonStyle = {
    textTransform: 'none',
    fontSize: '16px',
  };

  const animationOptions = {
    animationData: newIconAnimation,
    loop: true,
    autoplay: true,
  };

  const data = [
    {
      icon: (
        <GreenCircle
          width={20}
          height={20}
          style={{ minHeight: '20px', minWidth: '20px' }}
        />
      ),
      title: t('featurePopups.preferecePopup.first.title'),
      subTitle: t('featurePopups.preferecePopup.first.subtitle'),
      link: t('featurePopups.preferecePopup.first.link'),
    },
    {
      icon: (
        <CalwithDate
          width={20}
          height={20}
          style={{ minHeight: '20px', minWidth: '20px' }}
        />
      ),
      title: t('featurePopups.preferecePopup.second.title'),
      subTitle: t('featurePopups.preferecePopup.second.subtitle'),
      link: t('featurePopups.preferecePopup.second.link'),
    },
  ];

  return (
    <>
      {createPortal(
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/
        /* @ts-ignore*/
        <AnimatePresence>
          {isOpen && (
            <Wrapper
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              <StyledPopup>
                <CloseButton onClick={onClose} />
                <PerfectScrollbar
                  options={{
                    wheelSpeed: 0.1,
                    wheelPropagation: false,
                    minScrollbarLength: 4,
                  }}
                >
                  <StyledHeader>
                    <div style={{ position: 'relative', bottom: '0px' }}>
                      <Image
                        src={imageUrl}
                        style={{
                          border: 'none',
                          marginBottom: '0px',
                          objectPosition: '0px 0px',
                        }}
                        width="70%"
                      />
                    </div>
                  </StyledHeader>
                  <StyledBody>
                    <h3>
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                          justifyContent: 'center',
                        }}
                      >
                        <Lottie
                          options={animationOptions}
                          width="48px"
                          height="48px"
                          style={{ margin: '0px' }}
                        />
                      </span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t('featurePopups.preferecePopup.title'),
                        }}
                      ></span>
                    </h3>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '10px',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        padding: '12px 0px 10px 0px',
                      }}
                    >
                      {data.map((li, index) => (
                        <div
                          key={index}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flex: '1',
                            padding: '16px 16px',
                            borderRadius: '16px',
                            backgroundColor: '#EBF2F7',
                            fontSize: '15px',
                            flexDirection: 'column',
                          }}
                        >
                          <div style={{ display: 'flex' }}>
                            <span>{li.icon}</span>
                            <p
                              style={{
                                textAlign: 'center',
                                marginLeft: '4px',
                                lineHeight: 1.3,
                              }}
                            >
                              <strong>{li.title}</strong>
                            </p>
                          </div>
                          <p
                            style={{
                              paddingLeft: '2px',
                              fontSize: '16px',
                              lineHeight: '22px',
                            }}
                          >
                            {li.subTitle}
                            <StyledAnchorTag
                              target="_blank"
                              marginLeft="4px"
                              display="inline"
                              href={pushSupportPageUrl}
                            >
                              {li.link}
                            </StyledAnchorTag>
                          </p>
                        </div>
                      ))}
                    </div>
                  </StyledBody>
                  <StyledFooter>
                    <Button
                      variant="secondary"
                      onClick={onClose}
                      buttonStyles={buttonStyle}
                      {...{ rounded: true }}
                    >
                      {t('featurePopups.importEvent.cancelButton')}
                    </Button>
                    <Button
                      onClick={handleClick}
                      buttonStyles={{ ...buttonStyle, color: 'black' }}
                      {...{ rounded: true }}
                    >
                      {t('featurePopups.importEvent.okButton')}
                    </Button>
                  </StyledFooter>
                </PerfectScrollbar>
              </StyledPopup>
            </Wrapper>
          )}
        </AnimatePresence>,
        document.getElementById('modal'),
      )}
    </>
  );
};

export default PrefrencePopup;
