// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { handleActions } from 'redux-actions';
import { assoc, compose, getOr, reject, cloneDeep } from 'lodash/fp';

import { doUpdateCommunity } from 'store/actionCreators/communities';
import { doSetOnlineMembers } from 'store/actionCreators/chat';
import {
  doUpdateCommunityNotificationsSettings,
  doRequestCurrentCommunity,
  doRequestOverallScheduler,
  doClearOverallScheduler,
  doCreateCommunityDefaultEventSetting,
  doGetCommunityResponses,
  doEditCommunityResponse,
  doDeleteCommunityResponse,
  doCreateCommunityResponse,
  doCreateCommunityTask,
  doEditCommunityTask,
  doDeleteCommunityTask,
  doGetCommunityTasks,
  doDeleteDocument,
  doGetDocuments,
  doGetDocumentsOverview,
  doUploadDocument,
  doRequestRequests,
  doDeleteCommunityUser,
  doAddCommunityUser,
  doChangeUserRole,
  doRequestEvents,
  doRequestOverallEvents,
  doCreateEvent,
  doUpdateEvents,
  doDeleteEvents,
  doCreateEventReply,
  doCreateOverallEventReply,
  doUpdateEventReply,
  doDeleteEventReply,
  doUpdateOverallEventReply,
  doDeleteOverallEventReply,
  doGetCommunityLanding,
  doApproveRequests,
  doSendRequest,
  doRejectRequests,
  doClearCurrentCommunityError,
  doCreateCommunityEvent,
  doUpdateCommunityEvents,
  doGetCommunityTotal,
  doDeleteCommunityEvents,
  doGetCommunityMembersByGroups,
  doAddMemberToGroup,
  doRemoveMemberFromGroup,
  doGetOverallScheduleStatistics,
  doToggleMenu,
  doRearrangeGroups,
  doInviteMember,
  doChangeUserProfile,
  doClearCurrentCommunity,
  doClearOverallEvents,
  doClearGroupEvents,
  doRenewInvitationCode,
  doGetCommunityUserGroups,
  doClearCommunityUsersGroups,
  doCreatePushTemplate,
  doGetPushTemplates,
  doDeletePushTemplate,
  doSendPushNotification,
  doGetCommunityDefaultEventSetting,
  doEditCommunityDefaultEventSetting,
  doDeleteCommunityDefaultEventSetting,
  doCreateBulkEvents,
  doDeleteBulkEvents,
  doGetEventUploadStatus,
  doResetUploadStatus,
  doClearEventUploadStatus,
} from 'store/actionCreators/currentCommunity';
import {
  doCreateGroup,
  doDeleteGroup,
  doUpdateGroup,
} from 'store/actionCreators/groups';
import { Community } from 'types';

type CurrentCommunityStateLoaders = {
  currentCommunity: boolean;
  overallScheduler: boolean;
  membersByGroups: boolean;
  communityUserGroups: string[];
  getEventTypes: boolean;
  createEventType: boolean;
  getDefaultEventSetting: boolean;
  createDefaultEventSetting: boolean;
  deleteDefaultEventSetting: boolean;
  editDefaultEventSetting: boolean;
  editEventType: boolean;
  deleteEventType: boolean;
  addCommunityMember: boolean;
  getStatistic: boolean;
  updateCommunity: boolean;
  createEventReply: boolean;
  updateEventReply: boolean;
  createEventTask: boolean;
  editEventTask: boolean;
  deleteEventTask: boolean;
  createEventResponse: boolean;
  editEventResponse: boolean;
  deleteEventResponse: boolean;
  requestEvents: boolean;
  overallEvents: boolean;
  createEvent: boolean;
  deleteEvent: boolean;
  addMemberToGroup: boolean;
  removeMemberFromGroup: boolean;
  sendRequests: boolean;
  uploadDocument: boolean;
  createGroup: boolean;
  changeUserRole: boolean;
  approveRequests: boolean;
  rejectRequests: boolean;
  deleteCommunityUser: boolean;
  getRequests: boolean;
  renewInvitationCode: boolean;
  getTemplates: boolean;
  createTemplate: boolean;
  deleteTemplate: boolean;
  sendPushNotification: boolean;
  getBulkUploadStatus: boolean;
};

export interface CurrentCommunityState {
  data: Community;
  errors: any;
  loaders: CurrentCommunityStateLoaders;
  isMenuOpen: string | null;
}

const initialState: CurrentCommunityState = {
  data: {
    id: null,
    name: null,
    category: null,
    createdAt: null,
    updatedAt: null,
    deletedAt: null,
    description: null,
    inviteLink: null,
    invitationCode: null,
    links: [],
    notifications: null,
    usersCount: null,
    overallScheduler: null,
    logo: null,
    cover: null,
    communityOwner: null,
    users: null,
    info: null,
    subscription: null,
    chatUsers: null,
    eventTypes: [],
    defaultEventSetting: [],
    eventTasks: [],
    eventResponses: [],
    events: [],
    requests: [],
    groups: [],
    groupsCount: null,
    membersByGroups: [],
    documentation: {
      overview: null,
      documents: [],
    },
    statistic: null,
    userPushTemplates: [],
    bulkEventUpload: { uploadEventsCount: 0, uploadStatus: '' },
  },
  errors: {},
  loaders: {
    currentCommunity: false,
    overallScheduler: false,
    membersByGroups: false,
    communityUserGroups: [],
    getEventTypes: false,
    createEventType: false,
    createDefaultEventSetting: false,
    deleteDefaultEventSetting: false,
    editDefaultEventSetting: false,
    getDefaultEventSetting: false,
    editEventType: false,
    deleteEventType: false,
    addCommunityMember: false,
    getStatistic: false,
    updateCommunity: false,
    createEventReply: false,
    updateEventReply: false,
    createEventTask: false,
    editEventTask: false,
    deleteEventTask: false,
    createEventResponse: false,
    editEventResponse: false,
    deleteEventResponse: false,
    requestEvents: false,
    overallEvents: false,
    createEvent: false,
    deleteEvent: false,
    addMemberToGroup: false,
    removeMemberFromGroup: false,
    sendRequests: false,
    uploadDocument: false,
    createGroup: false,
    changeUserRole: false,
    approveRequests: false,
    rejectRequests: false,
    deleteCommunityUser: false,
    getRequests: false,
    renewInvitationCode: false,
    getTemplates: false,
    createTemplate: false,
    deleteTemplate: false,
    sendPushNotification: false,
    getBulkUploadStatus: false,
  },
  isMenuOpen: localStorage.getItem('menuShown'),
};

export default handleActions<CurrentCommunityState, any>(
  {
    [doRequestCurrentCommunity.TRIGGER]: (state) =>
      compose(
        assoc(['loaders', 'currentCommunity'], true),
        assoc(['data'], initialState.data),
      )(state),
    [doRequestCurrentCommunity.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'currentCommunity'], false),
        assoc(['data'], payload),
        assoc(['errors', 'currentCommunity'], null),
      )(state),
    [doRequestCurrentCommunity.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'currentCommunity'], false),
        assoc(['data'], initialState.data),
        assoc(['errors', 'currentCommunity'], payload),
      )(state),
    [doRequestOverallScheduler.TRIGGER]: (state) =>
      compose(
        assoc(['loaders', 'overallScheduler'], true),
        assoc(['data', 'overallScheduler'], null),
      )(state),
    [doRequestOverallScheduler.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'overallScheduler'], false),
        assoc(['data', 'overallScheduler'], payload),
        assoc(['errors', 'currentCommunity'], null),
      )(state),
    [doRequestOverallScheduler.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'overallScheduler'], false),
        assoc(['data', 'overallScheduler'], null),
        assoc(['errors', 'currentCommunity'], payload),
      )(state),
    [doClearOverallScheduler.TRIGGER]: (state) =>
      compose(assoc(['data', 'overallScheduler'], null))(state),
    [doGetCommunityMembersByGroups.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'membersByGroups'], true))(state),
    [doGetCommunityMembersByGroups.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'membersByGroups'], false),
        assoc(['data', 'membersByGroups'], payload),
        assoc(['errors', 'currentCommunity'], null),
      )(state),
    [doGetCommunityMembersByGroups.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'membersByGroups'], false),
        assoc(['data', 'membersByGroups'], {}),
        assoc(['errors', 'currentCommunity'], payload),
      )(state),
    [doGetCommunityUserGroups.TRIGGER]: (state, { payload }) => {
      return compose(
        assoc(
          ['loaders', 'communityUserGroups'],
          state.loaders?.communityUserGroups
            ? [...state.loaders.communityUserGroups, ...payload.userIds]
            : [...payload.userIds],
        ),
      )(state);
    },
    [doGetCommunityUserGroups.SUCCESS]: (state, { payload }) => {
      let newArr = state.data.users;
      const { usersGroups, userIds } = payload;

      if (usersGroups) {
        newArr = state.data.users.map((u) =>
          u.id in usersGroups ? { ...u, groups: usersGroups[u.id] } : u,
        );
      }

      return compose(
        assoc(['data', 'users'], newArr),
        assoc(
          ['loaders', 'communityUserGroups'],
          state.loaders?.communityUserGroups?.filter(
            (id) => !userIds.includes(id),
          ),
        ),
      )(state);
    },
    [doClearCommunityUsersGroups.TRIGGER]: (state) => {
      if (state?.data?.users) {
        return compose(
          assoc(
            ['data', 'users'],
            state.data.users.map((u) => ({ ...u, groups: null })),
          ),
        )(state);
      }
      return compose(assoc(['data', 'users'], null))(state);
    },
    [doClearCurrentCommunityError.TRIGGER]: (state) =>
      compose(assoc(['data'], initialState.data), assoc(['errors'], {}))(state),
    [doGetCommunityLanding.TRIGGER]: (state) =>
      compose(
        assoc(['loaders', 'currentCommunity'], true),
        assoc(['data'], initialState.data),
      )(state),
    [doGetCommunityLanding.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'currentCommunity'], false),
        assoc(['data'], payload),
      )(state),
    [doGetCommunityLanding.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'currentCommunity'], false),
        assoc(['data'], initialState.data),
        assoc(['errors', 'currentCommunity'], payload),
      )(state),
    [doGetCommunityDefaultEventSetting.TRIGGER]: (state) =>
      compose(
        assoc(['loaders', 'getDefaultEventSetting'], true),
        assoc(['loaders', 'getEventTypes'], true),
      )(state),
    [doGetCommunityDefaultEventSetting.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'getDefaultEventSetting'], false),
        assoc(['loaders', 'getEventTypes'], false),
        assoc(['data', 'defaultEventSetting'], payload.data),
        assoc(
          ['data', 'eventTypes'],
          payload.data.map((li) => li.eventType),
        ),
      )(state),
    [doGetCommunityDefaultEventSetting.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'getEventsTypes'], false),
        assoc(['loaders', 'getDefaultEventSetting'], false),
        assoc(['errors', 'getEventTypes'], payload),
        assoc(['errors', 'getDefaultEventSetting'], payload),
      )(state),
    [doCreateCommunityDefaultEventSetting.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'createDefaultEventSetting'], true))(state),
    [doCreateCommunityDefaultEventSetting.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'createDefaultEventSetting'], false),
        assoc(
          ['data', 'defaultEventSetting'],
          [...state.data.defaultEventSetting, payload],
        ),
        assoc(
          ['data', 'eventTypes'],
          [...state.data.eventTypes, payload.eventType],
        ),
      )(state),
    [doCreateCommunityDefaultEventSetting.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'createDefaultEventSetting'], false),
        assoc(['errors', 'createDefaultEventSetting'], payload),
      )(state),
    [doEditCommunityDefaultEventSetting.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'editDefaultEventSetting'], true))(state),
    [doEditCommunityDefaultEventSetting.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'editDefaultEventSetting'], false),
        assoc(
          ['data', 'defaultEventSetting'],
          [
            ...state.data.defaultEventSetting.map((i) =>
              i.eventType.id === payload.eventType.id ? payload : i,
            ),
          ],
        ),
        assoc(
          ['data', 'eventTypes'],
          [
            ...state.data.eventTypes.map((i) =>
              i.id === payload.eventType.id ? payload.eventType : i,
            ),
          ],
        ),
      )(state),
    [doEditCommunityDefaultEventSetting.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'editDefaultEventSetting'], false),
        assoc(['errors', 'editDefaultEventSetting'], payload),
      )(state),
    [doDeleteCommunityDefaultEventSetting.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'deleteDefaultEventSetting'], true))(state),
    [doDeleteCommunityDefaultEventSetting.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'deleteDefaultEventSetting'], false),
        assoc(
          ['data', 'defaultEventSetting'],
          [
            ...state.data.defaultEventSetting.map((typeEventSetting) => {
              if (typeEventSetting.eventType.id === payload.id)
                typeEventSetting.eventType.deletedAt = Date.now().toString();
              return typeEventSetting;
            }),
          ],
        ),
        assoc(
          ['data', 'eventTypes'],
          [
            ...state.data.eventTypes.map((eventType) => {
              if (eventType.id === payload.id)
                eventType.deletedAt = Date.now().toString();
              return eventType;
            }),
          ],
        ),
      )(state),
    [doDeleteCommunityDefaultEventSetting.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'deleteDefaultEventSetting'], false),
        assoc(['errors', 'deleteDefaultEventSetting'], payload),
      )(state),
    [doGetCommunityResponses.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'getEventResponses'], true))(state),
    [doGetCommunityResponses.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'getEventResponses'], false),
        assoc(['data', 'eventResponses'], payload.data),
      )(state),
    [doGetCommunityResponses.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'getEventResponses'], false),
        assoc(['errors', 'getEventResponses'], payload),
      )(state),
    [doDeleteCommunityResponse.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'deleteEventResponse'], true))(state),
    [doDeleteCommunityResponse.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'deleteEventResponse'], false),
        assoc(
          ['data', 'eventResponses'],
          reject({ id: payload.id }, state.data.eventResponses),
        ),
      )(state),
    [doDeleteCommunityResponse.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'deleteEventResponse'], false),
        assoc(['errors', 'deleteEventResponse'], payload),
      )(state),
    [doCreateCommunityResponse.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'createEventResponse'], true))(state),
    [doCreateCommunityResponse.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'createEventResponse'], false),
        assoc(
          ['data', 'eventResponses'],
          [...state.data.eventResponses, payload],
        ),
      )(state),
    [doCreateCommunityResponse.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'createEventResponse'], false),
        assoc(['errors', 'createEventResponse'], payload),
      )(state),
    [doEditCommunityResponse.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'editEventResponse'], true))(state),
    [doEditCommunityResponse.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'editEventResponse'], false),
        assoc(
          ['data', 'eventResponses'],
          [
            ...state.data.eventResponses.map((i) =>
              i.id === payload.id ? payload : i,
            ),
          ],
        ),
      )(state),
    [doEditCommunityResponse.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'editEventResponse'], false),
        assoc(['errors', 'editEventResponse'], payload),
      )(state),
    [doGetCommunityTasks.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'getEventTasks'], true))(state),
    [doGetCommunityTasks.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'getEventTasks'], false),
        assoc(['data', 'eventTasks'], payload),
      )(state),
    [doGetCommunityTasks.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'getEventTasks'], false),
        assoc(['errors', 'getEventTasks'], payload),
      )(state),
    [doDeleteCommunityTask.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'deleteEventTask'], true))(state),
    [doDeleteCommunityTask.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'deleteEventTask'], false),
        assoc(
          ['data', 'eventTasks'],
          reject({ id: payload.id }, state.data.eventTasks),
        ),
      )(state),
    [doDeleteCommunityTask.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'deleteEventTask'], false),
        assoc(['errors', 'deleteEventTask'], payload),
      )(state),
    [doCreateCommunityTask.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'createEventTask'], true))(state),
    [doCreateCommunityTask.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'createEventTask'], false),
        assoc(['data', 'eventTasks'], [...state.data.eventTasks, payload]),
      )(state),
    [doCreateCommunityTask.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'createTask'], false),
        assoc(['errors', 'createEventTask'], payload),
      )(state),
    [doEditCommunityTask.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'editEventTask'], true))(state),
    [doEditCommunityTask.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'editEventTask'], false),
        assoc(
          ['data', 'eventTasks'],
          [
            ...state.data.eventTasks.map((i) =>
              i.id === payload.id ? payload : i,
            ),
          ],
        ),
      )(state),
    [doEditCommunityTask.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'editEventTask'], false),
        assoc(['errors', 'editEventTask'], payload),
      )(state),
    [doRequestEvents.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'requestEvents'], true))(state),
    [doRequestEvents.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'requestEvents'], false),
        assoc(
          ['data', 'events'],
          [...(state.data.events || []), ...payload.data],
        ),
        assoc(['data', 'totalCountEvents'], payload.totalCount),
      )(state),
    [doRequestEvents.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'requestEvents'], false),
        assoc(['errors', 'requestEvents'], payload),
      )(state),
    [doClearGroupEvents.TRIGGER]: (state) =>
      compose(assoc(['data', 'events'], []))(state),
    [doRequestOverallEvents.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'overallEvents'], true))(state),
    [doRequestOverallEvents.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'overallEvents'], false),
        assoc(
          ['data', 'overallScheduler', 'events'],
          [...(state.data.overallScheduler?.events || []), ...payload.data],
        ),
        assoc(
          ['data', 'overallScheduler', 'totalCountEvents'],
          payload.totalCount,
        ),
      )(state),
    [doRequestOverallEvents.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'overallEvents'], false),
        assoc(['errors', 'requestEvents'], payload),
      )(state),
    [doClearOverallEvents.TRIGGER]: (state) =>
      compose(assoc(['data', 'overallScheduler', 'events'], []))(state),
    [doCreateEvent.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'createEvent'], true))(state),
    [doCreateEvent.SUCCESS]: (state, { payload }) =>
      compose(
        payload?.reload
          ? assoc(['loaders', 'createEvent'], false)
          : assoc(['loaders', 'createEvent'], false),
        Array.isArray(payload)
          ? assoc(['data', 'events'], [...state.data.events, ...payload])
          : assoc(['data', 'events'], [...state.data.events, payload]),
      )(state),
    [doCreateEvent.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'createEvent'], false),
        assoc(['errors', 'createEvent'], payload),
      )(state),
    [doUpdateEvents.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'createEvent'], true))(state),
    [doUpdateEvents.SUCCESS]: (state, { payload }) =>
      compose(
        payload?.reload
          ? assoc(['loaders', 'createEvent'], false)
          : assoc(['loaders', 'createEvent'], false),
        assoc(
          ['data', 'events'],
          state.data.events.map((events) => {
            const returnedArray = payload.response.find(
              (item) => item.id === events.id,
            );
            return returnedArray || events;
          }),
        ),
      )(state),
    [doUpdateEvents.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'createEvent'], false),
        assoc(['errors', 'updateEvent'], payload),
      )(state),
    [doDeleteEvents.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'deleteEvent'], true))(state),
    [doDeleteEvents.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'deleteEvent'], false),
        assoc(
          ['data', 'events'],
          state.data.events.filter((event) => !payload.ids.includes(event.id)),
        ),
      )(state),
    [doDeleteEvents.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'deleteEvent'], false),
        assoc(['errors', 'deleteEvent'], payload),
      )(state),
    [doCreateCommunityEvent.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'createEvent'], true))(state),
    [doCreateCommunityEvent.SUCCESS]: (state, { payload }) =>
      compose(
        payload?.reload
          ? assoc(['loaders', 'createEvent'], false)
          : (assoc(['loaders', 'createEvent'], false),
            Array.isArray(payload)
              ? assoc(
                  ['data', 'overallScheduler', 'events'],
                  [...state.data.overallScheduler.events, ...payload],
                )
              : assoc(
                  ['data', 'overallScheduler', 'events'],
                  [...state.data.overallScheduler.events, payload],
                )),
      )(state),
    [doCreateCommunityEvent.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'createEvent'], false),
        assoc(['errors', 'createEvent'], payload),
      )(state),
    [doUpdateCommunityEvents.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'createEvent'], true))(state),
    [doUpdateCommunityEvents.SUCCESS]: (state, { payload }) =>
      compose(
        payload?.reload
          ? assoc(['loaders', 'createEvent'], false)
          : assoc(['loaders', 'createEvent'], false),
        state.data.overallScheduler?.events
          ? assoc(
              ['data', 'overallScheduler', 'events'],
              state.data.overallScheduler.events.map((event) => {
                const returnedArray = payload.response.find(
                  (item) => item.id === event.id,
                );
                return returnedArray || event;
              }),
            )
          : assoc(
              ['data', 'events'],
              state.data.events.map((event) => {
                const returnedArray = payload.response.find(
                  (item) => item.id === event.id,
                );
                return returnedArray || event;
              }),
            ),
      )(state),
    [doUpdateCommunityEvents.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'createEvent'], false),
        assoc(['errors', 'updateEvent'], payload),
      )(state),
    [doDeleteCommunityEvents.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'deleteEvent'], true))(state),
    [doDeleteCommunityEvents.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'deleteEvent'], false),
        state.data.overallScheduler?.events
          ? assoc(
              ['data', 'overallScheduler', 'events'],
              state.data.overallScheduler.events.filter(
                (event) => !payload.ids.includes(event.id),
              ),
            )
          : assoc(
              ['data', 'events'],
              state.data.events.filter(
                (event) => !payload.ids.includes(event.id),
              ),
            ),
      )(state),
    [doDeleteCommunityEvents.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'deleteEvent'], false),
        assoc(['errors', 'deleteEvent'], payload),
      )(state),
    [doCreateEventReply.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'createEventReply'], true))(state),
    [doCreateEventReply.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'createEventReply'], false),
        assoc(
          ['data', 'events'],
          state.data.events.map((e) => {
            if (!payload.some((i) => i.subEventReply.eventId === e.id)) {
              return e;
            }

            const subEventReplies = e.subEventReplies.filter(
              (i) => !payload.some((j) => j.subEventReply.id === i.id),
            );
            const subEventTaskReplies =
              payload.find((i) => i.subEventTaskReplies[0]?.eventId === e.id)
                ?.subEventTaskReplies || [];

            return {
              ...e,
              subEventReplies: [
                ...subEventReplies,
                ...payload
                  .map((i) => i.subEventReply)
                  .filter((i) => i.eventId === e.id),
              ],
              subEventTaskReplies,
            };
          }),
        ),
      )(state),
    [doCreateOverallEventReply.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'createEventReply'], true))(state),
    [doCreateOverallEventReply.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'createEventReply'], false),
        state.data.overallScheduler?.events
          ? assoc(
              ['data', 'overallScheduler', 'events'],
              state.data.overallScheduler.events.map((e) => {
                if (!payload.some((i) => i.subEventReply.eventId === e.id)) {
                  return e;
                }

                const subEventReplies = e.subEventReplies.filter(
                  (i) => !payload.some((j) => j.subEventReply.id === i.id),
                );
                const subEventTaskReplies =
                  payload.find(
                    (i) => i.subEventTaskReplies[0]?.eventId === e.id,
                  )?.subEventTaskReplies || [];

                return {
                  ...e,
                  subEventReplies: [
                    ...subEventReplies,
                    ...payload
                      .map((i) => i.subEventReply)
                      .filter((i) => i.eventId === e.id),
                  ],
                  subEventTaskReplies,
                };
              }),
            )
          : assoc(
              ['data', 'events'],
              state.data.events.map((e) => {
                if (!payload.some((i) => i.subEventReply.eventId === e.id)) {
                  return e;
                }

                const subEventReplies = e.subEventReplies.filter(
                  (i) => !payload.some((j) => j.subEventReply.id === i.id),
                );
                const subEventTaskReplies =
                  payload.find(
                    (i) => i.subEventTaskReplies[0]?.eventId === e.id,
                  )?.subEventTaskReplies || [];

                return {
                  ...e,
                  subEventReplies: [
                    ...subEventReplies,
                    ...payload
                      .map((i) => i.subEventReply)
                      .filter((i) => i.eventId === e.id),
                  ],
                  subEventTaskReplies,
                };
              }),
            ),
      )(state),
    [doCreateOverallEventReply.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'createEventReply'], false),
        assoc(['errors', 'createEventReply'], payload),
      )(state),
    [doUpdateEventReply.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'updateEventReply'], true))(state),
    [doUpdateEventReply.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'updateEventReply'], false),
        assoc(
          ['data', 'events'],
          state.data.events.map((e) => {
            if (
              !payload.response.some((i) => i.subEventReply.eventId === e.id)
            ) {
              return e;
            }

            const subEventReplies = e.subEventReplies.filter(
              (i) => !payload.response.some((j) => j.subEventReply.id === i.id),
            );
            const newSubEventTaskReplies =
              payload.response.find(
                (i) => i.subEventTaskReplies[0]?.eventId === e.id,
              )?.subEventTaskReplies || [];

            for (const subEventTaskReply of e.subEventTaskReplies) {
              if (subEventTaskReply.userId != payload.userId) {
                newSubEventTaskReplies.push(subEventTaskReply);
              }
            }

            return {
              ...e,
              subEventReplies: [
                ...subEventReplies,
                ...payload.response
                  .map((i) => i.subEventReply)
                  .filter((i) => i.eventId === e.id),
              ],
              subEventTaskReplies: newSubEventTaskReplies,
            };
          }),
        ),
      )(state),
    [doDeleteEventReply.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'updateEventReply'], true))(state),
    [doDeleteEventReply.SUCCESS]: (state, { payload }) => {
      const { eventIds, userId } = payload;

      const newEvents = state.data.events.map((e) => {
        if (eventIds.includes(e.id)) {
          const subEventReplies = e.subEventReplies.filter(
            (i) => i.userId != userId,
          );

          const subEventTaskReplies = e.subEventTaskReplies.filter(
            (i) => i.userId != userId,
          );

          return {
            ...e,
            subEventReplies: subEventReplies,
            subEventTaskReplies: subEventTaskReplies,
          };
        } else {
          return e;
        }
      });

      return compose(
        assoc(['loaders', 'updateEventReply'], false),
        assoc(['data', 'events'], newEvents),
      )(state);
    },
    [doUpdateOverallEventReply.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'updateEventReply'], true))(state),
    [doUpdateOverallEventReply.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'updateEventReply'], false),
        state.data.overallScheduler?.events
          ? assoc(
              ['data', 'overallScheduler', 'events'],
              state.data.overallScheduler.events.map((e) => {
                if (!payload.some((i) => i.subEventReply.eventId === e.id)) {
                  return e;
                }

                const subEventReplies = e.subEventReplies.filter(
                  (i) => !payload.some((j) => j.subEventReply.id === i.id),
                );
                const subEventTaskReplies =
                  payload.find(
                    (i) => i.subEventTaskReplies[0]?.eventId === e.id,
                  )?.subEventTaskReplies || [];

                return {
                  ...e,
                  subEventReplies: [
                    ...subEventReplies,
                    ...payload
                      .map((i) => i.subEventReply)
                      .filter((i) => i.eventId === e.id),
                  ],
                  subEventTaskReplies,
                };
              }),
            )
          : assoc(
              ['data', 'events'],
              state.data.events.map((e) => {
                if (!payload.some((i) => i.subEventReply.eventId === e.id)) {
                  return e;
                }

                const subEventReplies = e.subEventReplies.filter(
                  (i) => !payload.some((j) => j.subEventReply.id === i.id),
                );
                const subEventTaskReplies =
                  payload.find(
                    (i) => i.subEventTaskReplies[0]?.eventId === e.id,
                  )?.subEventTaskReplies || [];

                return {
                  ...e,
                  subEventReplies: [
                    ...subEventReplies,
                    ...payload
                      .map((i) => i.subEventReply)
                      .filter((i) => i.eventId === e.id),
                  ],
                  subEventTaskReplies,
                };
              }),
            ),
      )(state),
    [doUpdateOverallEventReply.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'updateEventReply'], false),
        assoc(['errors', 'updateEventReply'], payload),
      )(state),
    [doDeleteOverallEventReply.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'updateEventReply'], true))(state),
    [doDeleteOverallEventReply.SUCCESS]: (state, { payload }) => {
      const { eventIds, userId } = payload;

      let newEvents = [];

      if (state.data.overallScheduler?.events) {
        newEvents = state.data.overallScheduler.events.map((e) => {
          if (eventIds.includes(e.id)) {
            const subEventReplies = e.subEventReplies.filter(
              (i) => i.userId != userId,
            );

            const subEventTaskReplies = e.subEventTaskReplies.filter(
              (i) => i.userId != userId,
            );

            return {
              ...e,
              subEventReplies: [...subEventReplies],
              subEventTaskReplies: [...subEventTaskReplies],
            };
          } else {
            return e;
          }
        });
      } else {
        newEvents = state.data.events.map((e) => {
          if (eventIds.includes(e.id)) {
            const subEventReplies = e.subEventReplies.filter(
              (i) => i.userId != userId,
            );

            const subEventTaskReplies = e.subEventTaskReplies.filter(
              (i) => i.userId != userId,
            );

            return {
              ...e,
              subEventReplies: [...subEventReplies],
              subEventTaskReplies: [...subEventTaskReplies],
            };
          } else {
            return e;
          }
        });
      }

      return compose(
        assoc(['loaders', 'updateEventReply'], false),
        assoc(
          state.data.overallScheduler?.events
            ? ['data', 'overallScheduler', 'events']
            : ['data', 'events'],
          newEvents,
        ),
      )(state);
    },
    [doDeleteOverallEventReply.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'updateEventReply'], false),
        assoc(['errors', 'updateEventReply'], payload),
      )(state),
    [doGetDocumentsOverview.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'getDocumentsOverview'], true))(state),
    [doGetDocumentsOverview.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'getDocumentsOverview'], false),
        assoc(['data', 'documentation', 'overview'], payload.data),
      )(state),
    [doGetDocumentsOverview.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'getDocumentsOverview'], false),
        assoc(['errors', 'getDocumentsOverview'], payload),
      )(state),
    [doGetDocuments.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'getDocuments'], true))(state),
    [doGetDocuments.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'getDocuments'], false),
        assoc(['data', 'documentation', 'documents'], payload.data),
      )(state),
    [doGetDocuments.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'getDocuments'], false),
        assoc(['errors', 'getDocuments'], payload),
      )(state),
    [doUploadDocument.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'uploadDocument'], true))(state),
    [doUploadDocument.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'uploadDocument'], false),
        assoc(
          ['data', 'documentation', 'documents'],
          [payload, ...state.data.documentation.documents],
        ),
      )(state),
    [doUploadDocument.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'uploadDocument'], false),
        assoc(['errors', 'uploadDocument'], payload),
      )(state),
    [doDeleteDocument.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'deleteDocument'], true))(state),
    [doDeleteDocument.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'deleteDocument'], false),
        assoc(
          ['data', 'documentation', 'documents'],
          reject(
            { id: payload.documentId },
            state.data.documentation.documents,
          ),
        ),
      )(state),
    [doDeleteDocument.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'deleteDocument'], false),
        assoc(['errors', 'deleteDocument'], payload),
      )(state),
    [doRequestRequests.TRIGGER]: (state) =>
      compose(
        assoc(['data', 'requests'], []),
        assoc(['loaders', 'getRequests'], true),
      )(state),
    [doRequestRequests.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'getRequests'], false),
        assoc(['data', 'requests'], payload),
      )(state),
    [doRequestRequests.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'getRequests'], true),
        assoc(['errors', 'getRequests'], payload),
      )(state),
    [doSetOnlineMembers.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['data'], {
          ...state.data,
          chatUsers: payload,
        }),
      )(state),
    [doCreateGroup.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'createGroup'], true))(state),
    [doCreateGroup.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'createGroup'], false),
        assoc(
          ['data', 'groups'],
          [...getOr([], ['data', 'groups'], state), payload],
        ),
      )(state),
    [doCreateGroup.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'createGroup'], false),
        assoc(['errors', 'createGroup'], payload),
      )(state),
    [doUpdateGroup.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'updateGroup'], true))(state),
    [doUpdateGroup.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'updateGroup'], false),
        assoc(
          ['data', 'groups'],
          [
            ...state.data.groups.map((i) =>
              i.id === payload.id ? payload : i,
            ),
          ],
        ),
      )(state),
    [doUpdateGroup.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'updateGroup'], false),
        assoc(['errors', 'updateGroup'], payload),
      )(state),
    [doDeleteGroup.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'deleteGroup'], true))(state),
    [doDeleteGroup.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'deleteGroup'], false),
        assoc(
          ['data', 'groups'],
          [...reject({ id: payload.groupId }, state.data.groups)],
        ),
      )(state),
    [doDeleteGroup.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'deleteGroup'], false),
        assoc(['errors', 'deleteGroup'], payload),
      )(state),
    [doUpdateCommunity.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'updateGroup'], true))(state),
    [doUpdateCommunity.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'updateGroup'], false),
        assoc(['data'], { ...state.data, ...payload }),
      )(state),
    [doUpdateCommunity.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'updateCommunity'], false),
        assoc(['errors', 'updateCommunity'], payload),
      )(state),
    [doUpdateCommunityNotificationsSettings.TRIGGER]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'updateCommunity'], true),
        assoc(['data'], {
          ...state.data,
          notifications: payload.data.notifications,
        }),
      )(state),
    [doUpdateCommunityNotificationsSettings.SUCCESS]: (state) =>
      compose(assoc(['loaders', 'updateCommunity'], false))(state),
    [doUpdateCommunityNotificationsSettings.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'updateCommunity'], false),
        assoc(['errors', 'updateCommunity'], payload),
      )(state),
    [doDeleteCommunityUser.TRIGGER]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'deleteCommunityUser'], true),
        assoc(
          ['data', 'users'],
          !payload.isCurrentUser
            ? reject(
                {
                  id: payload.userId || (payload.userIds && payload.userIds[0]),
                },
                state.data.users,
              )
            : state.data.users,
        ),
      )(state),
    [doDeleteCommunityUser.SUCCESS]: (state) =>
      compose(assoc(['loaders', 'deleteCommunityUser'], false))(state),
    [doDeleteCommunityUser.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'deleteCommunityUser'], false),
        assoc(['errors', 'deleteCommunityUser'], payload),
      )(state),
    [doAddCommunityUser.SUCCESS]: (state) =>
      compose(assoc(['usersCommunity', 'addUserCommunities'], false))(state),
    [doChangeUserRole.TRIGGER]: (state, { payload }) => {
      const ind = state.data.users.findIndex(
        (user) => user.id === payload.userId,
      );

      return compose(
        assoc(['loaders', 'changeUserRole'], true),
        assoc(
          ['data', 'users', ind, 'communityPermission', 'role'],
          payload.data.role,
        ),
      )(state);
    },
    [doChangeUserProfile.SUCCESS]: (state, { payload }) => {
      const ind = state.data.users.findIndex(
        (user) => user.id === payload.userId,
      );

      if (state.data.overallScheduler?.users) {
        const updatedGroups = state.data.overallScheduler.groups.map((g) => ({
          ...g,
          users: g.users.map((u) =>
            u.id === payload.userId ? { ...u, profile: payload } : u,
          ),
        }));
        const index = state.data.overallScheduler.users.findIndex(
          (user) => user.id === payload.userId,
        );

        return compose(
          assoc(
            ['data', 'overallScheduler', 'users', index, 'profile'],
            payload,
          ),
          assoc(['data', 'overallScheduler', 'groups'], updatedGroups),
          assoc(['loaders', 'changeUserRole'], false),
          assoc(['data', 'users', ind, 'profile'], payload),
        )(state);
      }

      return compose(
        assoc(['loaders', 'changeUserRole'], false),
        assoc(['data', 'users', ind, 'profile'], payload),
      )(state);
    },
    [doChangeUserRole.SUCCESS]: (state) =>
      compose(assoc(['loaders', 'changeUserRole'], false))(state),
    [doChangeUserRole.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'changeUserRole'], false),
        assoc(['errors', 'changeUserRole'], payload),
      )(state),
    [doApproveRequests.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'approveRequests'], true))(state),
    [doApproveRequests.SUCCESS]: (state) =>
      compose(assoc(['loaders', 'approveRequests'], false))(state),
    [doApproveRequests.FAILURE]: (state) =>
      compose(assoc(['loaders', 'approveRequests'], false))(state),
    [doRejectRequests.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'rejectRequests'], true))(state),
    [doRejectRequests.SUCCESS]: (state) =>
      compose(assoc(['loaders', 'rejectRequests'], false))(state),
    [doRejectRequests.FAILURE]: (state) =>
      compose(assoc(['loaders', 'rejectRequests'], false))(state),
    [doSendRequest.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'sendRequests'], true))(state),
    [doSendRequest.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'sendRequests'], false),
        assoc(['data', 'invites'], [payload]),
      )(state),
    [doSendRequest.FAILURE]: (state) =>
      compose(assoc(['loaders', 'sendRequests'], false))(state),
    [doGetCommunityTotal.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'getTotal'], true))(state),
    [doGetCommunityTotal.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'getTotal'], false),
        assoc(['data', 'overallScheduler', 'total'], payload),
      )(state),
    [doGetCommunityTotal.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'getTotal'], false),
        assoc(['errors', 'getTotal'], payload.error),
      )(state),
    [doAddMemberToGroup.TRIGGER]: (state, { payload }) => {
      const userInd = state.data.users.findIndex(
        (u) => u.id === payload.user.id,
      );
      const user = state.data.users[userInd];

      if (user.groups) {
        user.groups.push(
          state.data.groups.find((g) => g.id === payload.groupId),
        );
      }

      if (state.data.membersByGroups) {
        const ind = state.data.membersByGroups.findIndex(
          (group) => group.id === payload.groupId,
        );
        const cloned = cloneDeep(state.data.membersByGroups[ind]?.users || []);

        cloned.push(payload.user);

        return compose(
          assoc(['data', 'membersByGroups', ind, 'users'], cloned),
          assoc(['data', 'users', userInd], user),
          assoc(['loaders', 'addMemberToGroup'], true),
        )(state);
      }

      return compose(assoc(['data', 'users', userInd], user))(state);
    },
    [doAddMemberToGroup.SUCCESS]: (state) =>
      compose(assoc(['loaders', 'addMemberToGroup'], false))(state),
    [doAddMemberToGroup.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'addMemberToGroup'], false),
        assoc(['errors', 'addMemberToGroup'], payload.error),
      )(state),
    [doRemoveMemberFromGroup.TRIGGER]: (state, { payload }) => {
      const userInd = state.data.users.findIndex(
        (u) => u.id === payload.user.id,
      );
      const user = state.data.users[userInd];

      if (user.groups) {
        user.groups = user.groups.filter((g) => g.id !== payload.groupId);
      }

      if (state.data.membersByGroups) {
        const ind = state.data.membersByGroups.findIndex(
          (group) => group.id === payload.groupId,
        );
        const cloned = cloneDeep(state.data.membersByGroups[ind].users);

        cloned.splice(
          cloned.findIndex((user) => user.id === payload.user.id),
          1,
        );

        return compose(
          assoc(['data', 'membersByGroups', ind, 'users'], cloned),
          assoc(['data', 'users', userInd], user),
          assoc(['loaders', 'removeMemberFromGroup'], true),
        )(state);
      }

      return compose(assoc(['data', 'users', userInd], user))(state);
    },
    [doRemoveMemberFromGroup.SUCCESS]: (state) =>
      compose(assoc(['loaders', 'removeMemberFromGroup'], false))(state),
    [doRemoveMemberFromGroup.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'removeMemberFromGroup'], false),
        assoc(['errors', 'removeMemberFromGroup'], payload.error),
      )(state),
    [doGetOverallScheduleStatistics.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'getStatistic'], true))(state),
    [doGetOverallScheduleStatistics.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'getStatistic'], false),
        assoc(['data', 'statistic'], payload),
      )(state),
    [doGetOverallScheduleStatistics.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'getStatistic'], false),
        assoc(['errors', 'getStatistic'], payload.error),
      )(state),
    [doToggleMenu.TRIGGER]: (state) =>
      compose(assoc(['isMenuOpen'], !state.isMenuOpen))(state),
    [doRearrangeGroups.SUCCESS]: (
      state,
      { payload: { newMenuList, newSchedulerList } },
    ) =>
      compose(
        assoc(['data', 'groups'], newMenuList),
        state.data.overallScheduler?.groups
          ? assoc(['data', 'overallScheduler', 'groups'], newSchedulerList)
          : () => state,
      )(state),
    [doInviteMember.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'addCommunityMember'], true))(state),
    [doInviteMember.SUCCESS]: (state, { payload }) => {
      return compose(
        assoc(['loaders', 'addCommunityMember'], false),
        assoc(
          ['data', 'users'],
          payload?.user
            ? [
                ...getOr([], ['data', 'users'], state),
                {
                  ...payload.user,
                  groups: payload.groups.map((g) =>
                    state.data.groups.find((i) => i.id === g.id),
                  ),
                },
              ]
            : state.data.users,
        ),
        assoc(
          ['data', 'membersByGroups'],
          payload?.groups && payload?.user
            ? getOr([], ['data', 'membersByGroups'], state).map((i) =>
                payload.groups.some((g) => g.id === i.id)
                  ? { ...i, users: [...i.users, payload.user] }
                  : i,
              )
            : state.data.membersByGroups,
        ),
      )(state);
    },
    [doInviteMember.FAILURE]: (state) =>
      compose(assoc(['loaders', 'addCommunityMember'], false))(state),
    [doClearCurrentCommunity.TRIGGER]: (state) =>
      compose(assoc(['data'], initialState.data))(state),
    [doRenewInvitationCode.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'renewInvitationCode'], true))(state),
    [doRenewInvitationCode.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'renewInvitationCode'], false),
        assoc(['data', 'invitationCode'], payload),
      )(state),
    [doRenewInvitationCode.FAILURE]: (state) =>
      compose(assoc(['loaders', 'renewInvitationCode'], false))(state),
    [doGetPushTemplates.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'getTemplates'], true))(state),
    [doGetPushTemplates.SUCCESS]: (state, { payload }) => {
      if (payload.length > 0) {
        const formattedList = payload.map((template) => ({
          id: template.id,
          title: template.messageTemplate.title,
          message: template.messageTemplate.message,
          isTemplate: template.isTemplate,
          createdAt: template.createdAt,
        }));
        return compose(
          assoc(['data', 'userPushTemplates'], formattedList),
          assoc(['loaders', 'getTemplates'], false),
        )(state);
      }
      return compose(
        assoc(['data', 'userPushTemplates'], payload),
        assoc(['loaders', 'getTemplates'], false),
      )(state);
    },
    [doCreatePushTemplate.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'createTemplate'], true))(state),
    [doCreatePushTemplate.SUCCESS]: (state, { payload }) => {
      const {
        id,
        isTemplate,
        messageTemplate: { title, message },
        createdAt,
      } = payload;
      const hasLastCreated = state.data.userPushTemplates.some(
        (template) => !template.isTemplate,
      );
      if (hasLastCreated) {
        const newList = [...state.data.userPushTemplates];
        newList.splice(1, 0, {
          id,
          isTemplate,
          title,
          message,
          createdAt,
        });
        return compose(
          assoc(['loaders', 'createTemplate'], false),
          assoc(['data', 'userPushTemplates'], newList),
        )(state);
      } else {
        return compose(
          assoc(['loaders', 'createTemplate'], false),
          assoc(
            ['data', 'userPushTemplates'],
            [
              { id, isTemplate, title, message, createdAt },
              ...state.data.userPushTemplates,
            ],
          ),
        )(state);
      }
    },
    [doDeletePushTemplate.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'deleteTemplate'], true))(state),
    [doDeletePushTemplate.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'deleteTemplate'], false),
        assoc(
          ['data', 'userPushTemplates'],
          state.data.userPushTemplates.filter(
            (template) => template.id !== payload.id,
          ),
        ),
      )(state),
    [doSendPushNotification.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'sendPushNotification'], true))(state),
    [doSendPushNotification.SUCCESS]: (state, { payload }) => {
      const {
        id,
        createdAt,
        isTemplate,
        messageTemplate: { title, message },
      } = payload;
      const hasLastCreated = state.data.userPushTemplates.some(
        (template) => !template.isTemplate,
      );
      const formattedData = { id, title, message, createdAt, isTemplate };
      if (hasLastCreated) {
        const newList = [...state.data.userPushTemplates];
        newList.splice(0, 1, formattedData);
        return compose(
          assoc(['loaders', 'sendPushNotification'], false),
          assoc(['data', 'userPushTemplates'], newList),
        )(state);
      } else {
        const newList = [...state.data.userPushTemplates];
        newList.splice(0, 0, formattedData);
        return compose(
          assoc(['loaders', 'sendPushNotification'], false),
          assoc(['data', 'userPushTemplates'], newList),
        )(state);
      }
    },
    [doGetEventUploadStatus.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'getBulkUploadStatus'], true))(state),
    [doGetEventUploadStatus.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'getBulkUploadStatus'], false),
        assoc(['data', 'bulkEventUpload'], payload),
      )(state),
    [doCreateBulkEvents.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['data', 'bulkEventUpload'], {
          ...state.data.bulkEventUpload,
          ...{
            uploadStatus: 'completed',
            uploadEventsCount: payload.uploadEventsCount,
          },
        }),
        assoc(['data', 'eventTypes'], payload.newEventTypeList),
      )(state),
    [doClearEventUploadStatus.TRIGGER]: (state) =>
      compose(
        assoc(['data', 'bulkEventUpload', 'uploadStatus'], ''),
        assoc(['data', 'bulkEventUpload', 'uploadEventsCount'], 0),
      )(state),
    [doDeleteBulkEvents.SUCCESS]: (state) =>
      compose(assoc(['data', 'bulkEventUpload', 'uploadStatus'], ''))(state),
    [doResetUploadStatus.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(
          ['data', 'bulkEventUpload', 'uploadStatus'],
          payload.uploadStatus,
        ),
      )(state),
  },
  initialState,
);
