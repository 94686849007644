import styled from 'styled-components';

import { breakpoints } from 'theme';

export const StyledWrapper = styled.div`
  width: 100%;
  height: 51px;
  padding-left: 21px;
  padding-right: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: ${({ theme, variant }) =>
    variant === 'primary' ? `1px solid ${theme.color.porcelain}` : null};
  box-shadow: ${({ variant }) =>
    variant === 'secondary' ? '0 2px 4px 0 rgba(0,0,0,0.08)' : null};

  @media ${breakpoints.downMd} {
    button:last-of-type {
      position: absolute;
      right: 56px;
    }
  }
`;

export const StyledLabel = styled.span`
  font-size: 16px;
  flex-grow: 1;
  text-align: center;
  text-transform: capitalize;

  @media ${breakpoints.downSmPlus} {
    max-width: 190px;
  }
`;
