import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver, useController, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { EventTypeAndDefaultEventSetting } from 'types';
import { convertEventDateTime } from 'sidebars/CreateEventOverall/utils';
import { generateRandomColor } from 'utils';

export enum EVENT_TYPE_FIELDS {
  TITLE = 'title',
  COLOR = 'color',
  IS_RESPONSE_REQUIRED = 'isResponseRequired',
}

export enum EVENT_TYPE_SETTING_FIELDS {
  IS_SET_DEFAULT_RES = 'isSetDefaultRes',
  EVENT_TITLE = 'eventTitle',
  LOCATION = 'location',
  DESCRIPTION = 'description',
  START_TIME = 'from',
  END_TIME = 'to',
  EVENT_RESPONSE_ID = 'defaultEventResponseId',
  EVENT_TYPE_ID = 'eventTypeId',
}

export enum EVENT_TYPE_DEF_AND_SETTING {
  EVENT_TYPE = 'eventType',
  DEFAULT_SETTING = 'defaultSetting',
}

export type EditCommunityEventTypeFormData = {
  [EVENT_TYPE_DEF_AND_SETTING.EVENT_TYPE]: {
    [EVENT_TYPE_FIELDS.TITLE]?: string;
    [EVENT_TYPE_FIELDS.COLOR]?: string;
    [EVENT_TYPE_FIELDS.IS_RESPONSE_REQUIRED]?: boolean;
  };
  [EVENT_TYPE_DEF_AND_SETTING.DEFAULT_SETTING]: {
    [EVENT_TYPE_SETTING_FIELDS.EVENT_TITLE]?: string;
    [EVENT_TYPE_SETTING_FIELDS.LOCATION]?: string;
    [EVENT_TYPE_SETTING_FIELDS.DESCRIPTION]?: string;
    [EVENT_TYPE_SETTING_FIELDS.EVENT_RESPONSE_ID]?: string | null;
    [EVENT_TYPE_SETTING_FIELDS.START_TIME]?: Date;
    [EVENT_TYPE_SETTING_FIELDS.END_TIME]?: Date;
    [EVENT_TYPE_SETTING_FIELDS.IS_SET_DEFAULT_RES]?: boolean;
    [EVENT_TYPE_SETTING_FIELDS.EVENT_TYPE_ID]?: string;
  };
};
export const useEditCommunityEventTypeForm = (
  eventTypeAndDefaultSettings: EventTypeAndDefaultEventSetting,
) => {
  const { t } = useTranslation();

  yup.setLocale({
    mixed: { required: t('errors.required') },
    string: {
      min: ({ min }) => t('errors.min', { value: min }),
      max: ({ max }) => t('errors.max', { value: max }),
    },
  });

  const resolver: Resolver<EditCommunityEventTypeFormData> = yupResolver(
    yup.object().shape({
      [EVENT_TYPE_DEF_AND_SETTING.EVENT_TYPE]: yup.object().shape({
        [EVENT_TYPE_FIELDS.TITLE]: yup
          .string()
          .required()
          .max(50)
          .min(3)
          .trim(),
        [EVENT_TYPE_FIELDS.COLOR]: yup.string().required().trim(),
      }),
      [EVENT_TYPE_DEF_AND_SETTING.DEFAULT_SETTING]: yup.object().shape({
        [EVENT_TYPE_SETTING_FIELDS.EVENT_TITLE]: yup
          .string()
          .nullable()
          .trim()
          .when({
            is: (val) => val !== null && val !== '',
            then: yup.string().min(3).max(1000),
          }),
        [EVENT_TYPE_SETTING_FIELDS.LOCATION]: yup
          .string()
          .nullable()
          .trim()
          .when({
            is: (val) => val !== null && val !== '',
            then: yup.string().min(3).max(1000),
          }),
        [EVENT_TYPE_SETTING_FIELDS.DESCRIPTION]: yup
          .string()
          .nullable()
          .trim()
          .when({
            is: (val) => val !== null && val !== '',
            then: yup.string().min(3).max(1000),
          }),
        [EVENT_TYPE_SETTING_FIELDS.EVENT_RESPONSE_ID]: yup.string().nullable(),
        [EVENT_TYPE_SETTING_FIELDS.IS_SET_DEFAULT_RES]: yup.boolean(),
      }),
    }),
  );

  const defaultValues = eventTypeAndDefaultSettings
    ? {
        [EVENT_TYPE_DEF_AND_SETTING.EVENT_TYPE]: {
          [EVENT_TYPE_FIELDS.TITLE]:
            eventTypeAndDefaultSettings.eventType.title || '',
          [EVENT_TYPE_FIELDS.COLOR]:
            eventTypeAndDefaultSettings.eventType.color ||
            generateRandomColor(),
        },
        [EVENT_TYPE_DEF_AND_SETTING.DEFAULT_SETTING]: {
          [EVENT_TYPE_SETTING_FIELDS.IS_SET_DEFAULT_RES]: true,
          [EVENT_TYPE_SETTING_FIELDS.EVENT_TITLE]:
            eventTypeAndDefaultSettings.defaultSetting?.eventTitle || '',
          [EVENT_TYPE_SETTING_FIELDS.LOCATION]:
            eventTypeAndDefaultSettings.defaultSetting?.location || '',
          [EVENT_TYPE_SETTING_FIELDS.DESCRIPTION]:
            eventTypeAndDefaultSettings.defaultSetting?.description || '',
          [EVENT_TYPE_SETTING_FIELDS.EVENT_RESPONSE_ID]:
            eventTypeAndDefaultSettings.defaultSetting
              ?.defaultEventResponseId || null,
          [EVENT_TYPE_SETTING_FIELDS.START_TIME]: convertEventDateTime(
            eventTypeAndDefaultSettings.defaultSetting?.from,
          ),
          [EVENT_TYPE_SETTING_FIELDS.END_TIME]: eventTypeAndDefaultSettings
            .defaultSetting?.to
            ? convertEventDateTime(
                eventTypeAndDefaultSettings.defaultSetting?.to,
              )
            : new Date(
                convertEventDateTime(
                  eventTypeAndDefaultSettings.defaultSetting?.from,
                ).getTime() +
                  15 * 60000,
              ),
        },
      }
    : {
        [EVENT_TYPE_DEF_AND_SETTING.EVENT_TYPE]: {
          [EVENT_TYPE_FIELDS.TITLE]: '',
          [EVENT_TYPE_FIELDS.COLOR]: generateRandomColor(),
        },
        [EVENT_TYPE_DEF_AND_SETTING.DEFAULT_SETTING]: {
          [EVENT_TYPE_SETTING_FIELDS.IS_SET_DEFAULT_RES]: true,
          [EVENT_TYPE_SETTING_FIELDS.EVENT_TITLE]: '',
          [EVENT_TYPE_SETTING_FIELDS.LOCATION]: '',
          [EVENT_TYPE_SETTING_FIELDS.DESCRIPTION]: '',
          [EVENT_TYPE_SETTING_FIELDS.EVENT_RESPONSE_ID]: null,
          [EVENT_TYPE_SETTING_FIELDS.START_TIME]: new Date(),
          [EVENT_TYPE_SETTING_FIELDS.END_TIME]: new Date(
            new Date().getTime() + 15 * 60000,
          ),
        },
      };

  const form = useForm<EditCommunityEventTypeFormData>({
    defaultValues,
    resolver,
    context: {
      notEmpty: (val) => val !== null && val !== '',
    },
  });

  const watchedValues = useWatch<{
    [EVENT_TYPE_SETTING_FIELDS.START_TIME]: Date;
    [EVENT_TYPE_SETTING_FIELDS.END_TIME]: Date;
    [EVENT_TYPE_SETTING_FIELDS.IS_SET_DEFAULT_RES]: boolean;
  }>({
    name: [
      `${EVENT_TYPE_DEF_AND_SETTING.DEFAULT_SETTING}.${EVENT_TYPE_SETTING_FIELDS.START_TIME}`,
      `${EVENT_TYPE_DEF_AND_SETTING.DEFAULT_SETTING}.${EVENT_TYPE_SETTING_FIELDS.END_TIME}`,
      `${EVENT_TYPE_DEF_AND_SETTING.DEFAULT_SETTING}.${EVENT_TYPE_SETTING_FIELDS.IS_SET_DEFAULT_RES}`,
    ],
    control: form.control,
  });
  const { field: eventResponseFieldProps } =
    useController<EditCommunityEventTypeFormData>({
      name: `${EVENT_TYPE_DEF_AND_SETTING.DEFAULT_SETTING}.${EVENT_TYPE_SETTING_FIELDS.EVENT_RESPONSE_ID}`,
      control: form.control,
    });

  return {
    ...form,
    watchedValues,
    eventResponseFieldProps,
  };
};
