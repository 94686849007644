import React, { memo } from 'react';
import Lottie from 'react-lottie';
import { Tooltip } from 'components';
import Comment from '../icons/Comment.png';
import Dot from '../icons/Oval.png';
import Team from '../icons/Team.png';
import { UserValue } from 'types';
import yellowStar from 'static/animations/yellowStar.json';
import {
  StyledCellContainer,
  StyledCellContent,
  StyledHighlight,
  StyledSelectionHighlight,
} from './styled';
import { useSelector } from 'react-redux';
import { modalSelector } from 'store/selectors/modal';
import { useTranslation } from 'react-i18next';
import { getZoomedValue } from 'utils';

interface CellProps {
  style: { [key: string]: string | number };
  zoom: number;
  isCurrentUser: boolean;
  cell: UserValue;
  divide?: 'left' | 'right';
  eventId?: string;
  userId?: string;
}

const Cell = memo<CellProps>(
  ({ style, cell = {}, zoom, isCurrentUser, divide, eventId, userId }) => {
    const { t } = useTranslation();
    const { color, guests, comment, commentText, star, dot, text, onClick } =
      cell || {};
    const { data, isOpen: communityModalOpen } = useSelector(modalSelector);

    return (
      // <Tooltip
      //   text='13 May 2024 at 20:35:16 GMT+5:30'
      //   zoom={zoom}
      //   opacity='1'
      //   offset={6 * zoom}
      //   tooltipStyle={{
      //     whiteSpace: 'pre-wrap',
      //     maxWidth: 200 * zoom + 'px',
      //     height: 'auto',
      //   }}
      // >
      <div style={style} id={eventId}>
        {!!isCurrentUser && <StyledHighlight zoom={zoom} />}
        {communityModalOpen &&
          data?.['eventId']?.toString() === eventId?.toString() && (
            <StyledSelectionHighlight zoom={zoom} />
          )}
        {communityModalOpen &&
          userId &&
          data?.['userId']?.toString() === userId && (
            <StyledHighlight zoom={zoom} size={5} />
          )}
        <StyledCellContainer
          zoom={zoom}
          clickable={!!onClick}
          divide={divide}
          isCurrentUser={isCurrentUser}
          onClick={onClick}
        >
          <StyledCellContent zoom={zoom} color={color}>
            {!!guests && (
              <>
                <img className="gumb-grid-cell-icon" src={Team} alt="team" />
                <span>{guests > 1 && `+${guests}`}</span>
              </>
            )}
            {!!star && (
              <Tooltip
                isVisible
                text={t('event.nominated')}
                zoom={zoom}
                opacity="1"
                offset={6 * zoom}
                tooltipStyle={{
                  whiteSpace: 'pre-wrap',
                  maxWidth: 200 * zoom + 'px',
                  height: 'auto',
                }}
              >
                <div
                  style={{
                    width: `${getZoomedValue(20, zoom, 'px')}`,
                    height: `${getZoomedValue(20, zoom, 'px')}`,
                  }}
                >
                  <Lottie
                    options={{
                      animationData: yellowStar,
                      autoplay: true,
                      loop: true,
                    }}
                    width={getZoomedValue(35, zoom, 'px')}
                    height={getZoomedValue(35, zoom, 'px')}
                    style={{
                      position: 'absolute',
                      top: `${getZoomedValue(2, zoom, 'px')}`,
                      transform: 'translateX(-22%)',
                    }}
                  />
                </div>
              </Tooltip>
            )}

            {!!dot && (
              <Tooltip
                isVisible
                text={t('event.havePenalty')}
                zoom={zoom}
                opacity="1"
                tooltipStyle={{
                  whiteSpace: 'pre-wrap',
                  maxWidth: 200 * zoom + 'px',
                  height: 'auto',
                }}
              >
                <img src={Dot} alt="dot" />
              </Tooltip>
            )}
            {!!comment && (
              <Tooltip
                isVisible
                text={commentText}
                zoom={zoom}
                opacity="1"
                tooltipStyle={{
                  whiteSpace: 'pre-wrap',
                  maxWidth: 200 * zoom + 'px',
                  height: 'auto',
                }}
              >
                <img src={Comment} alt="comment" />
              </Tooltip>
            )}
            {!!text && <span className="gumb-grid-cell-text">{text}</span>}
          </StyledCellContent>
        </StyledCellContainer>
      </div>
      // </Tooltip>
    );
  },
);

Cell.displayName = 'Cell';

export default Cell;
